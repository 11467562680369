import React, { useState, useEffect } from "react";
import "../Homepage/Homepage.css";
import Slider from "../Slider/Slider";
import { Link } from "react-router-dom";
import Modal from "../Modal/Modal";

const Homepage = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  const hideModal = () => {
    setShow(false);
  };
  return (
    <>
      <section className="slide-h">
        {/*
        <Modal show={show} handleClose={hideModal}>
          <img src="modal.jpeg" className="modal-img" alt="img" />
          <p className="modal-text">You are invited in this events</p>
        </Modal>*/}
        <Slider />
      </section>
      <section className="aboutstory-section">
        <div className="aboutstory-div">
          <h1 className="aboutstory-head">
            ABOUT Brosid Sports LLC MANAGEMENT
          </h1>
          <p className="aboutstory-subhead">
            A Legacy of Passion and Excellence
          </p>
        </div>
        <div className="twodiv-aboutstory">
          <div className="first-aboutstory">
            <p className="aboutstory-text">
              Brosid Sports LLC was founded in 2008 in the loving memory of
              Siddharth Bhambri, a passionate sports enthusiast whose spirit
              continues to inspire the company’s mission and values. The name
              "Brosid" is a heartfelt tribute, combining "Bro" from brother and
              "Sid" from Siddharth, symbolizing the enduring bond and commitment
              to honor his legacy. For the past 15 years, Brosid Sports LLC has
              been at the forefront of sports management, organizing a wide
              array of events that celebrate athleticism, teamwork, and the joy
              of competition. Specializing in cricket, football,and golf, the
              company has successfully hosted numerous high-profile events, each
              meticulously planned and executed to the highest standards.
            </p>
          </div>
          <div className="second-aboutstory">
            <p className="aboutstory-text">
              Brosid Sports LLC has a proven track record of successfully
              hosting numerous high-profile golf events, each meticulously
               executed to the highest standards. Dedicated to
              fostering a vibrant sports culture, also provides diverse
              platforms for athletes to showcase their talents and creates
              unforgettable experiences for fans and participants alike. They
         committed to advancing the sports industry by embracing
              cutting-edge technologies and innovative strategies, ensuring
              their events are always ahead of the curve. Through their
              unwavering commitment to excellence and innovation, It continues
              to make a significant impact in the world of sports, ensuring
              Siddharth Bhambri's legacy lives on through every event they
              organize.
            </p>
          </div>
        </div>
        <Link to="/contact">
          <button className="aboutstory-button">Contact Us</button>
        </Link>
      </section>
      <section id="gal" className="product-section">
        <div className="container-p">
          <div className="about-awardsbox">
            <div className="grid2">
              <figure className="effect-oscar">
                <figcaption>
                  <img src="p1.jpeg" className="img-p" />
                  <p>
                    BROSID T-SHIRT
                    <br />
                    <br />
                    -Brosid Sports LLC
                  </p>
                </figcaption>
              </figure>
              <figure>
                <figcaption>
                  <h1 className="head-p" style={{ paddingTop: 47 }}>
                    Our <br /> Gallery
                  </h1>
                </figcaption>
              </figure>
              <figure className="effect-oscar">
                <figcaption>
                  <img src="p2.jpeg" className="img-p" />
                  <p>
                    BROSID BALL
                    <br />
                    <br />
                    -Brosid Sports LLC
                  </p>
                </figcaption>
              </figure>
              <figure className="effect-oscar">
                <figcaption>
                  <img src="p3.jpeg" className="img-p" />
                  <p>
                    BROSID STUMPS
                    <br />
                    <br />
                    -Brosid Sports LLC
                  </p>
                </figcaption>
              </figure>
              <figure className="effect-oscar">
                <figcaption>
                  <img src="p4.jpeg" className="img-p" />
                  <p>
                    BROSID AWARDS
                    <br />
                    <br />
                    -Brosid Sports LLC
                  </p>
                </figcaption>
              </figure>

              <figure className="effect-oscar">
                <figcaption>
                  <img src="p5.jpeg" className="img-p" />
                  <p>
                    BROSID POSTER
                    <br />
                    <br />
                    -Brosid Sports LLC
                  </p>
                </figcaption>
              </figure>
              <figure className="effect-oscar">
                <figcaption>
                  <img src="p6.jpeg" className="img-p" />
                  <p>
                    BROSID POSTER
                    <br />
                    <br />
                    -Brosid Sports LLC
                  </p>
                </figcaption>
              </figure>
            </div>
            <div className="butt-div">
              <Link to="/contact">
                <button className="button-product">Contact Now</button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section id="main" className="service-main">
        <h1>Our Services</h1>
        <div className="cards-list">
          <div className="card card1">
            <div className="card_image">
              <a href="#">
                <img src="brol.png" className="logo-se" />
              </a>
            </div>
            <div className="card_title title-white">
              <h6 className="heading">MEDIA RIGHTS</h6>
              <p>Understanding Media Rights in Brosid Sports LLC</p>
            </div>
            <div className="btn-read">
              <Link
                to="/services"
                className="btn-flip"
                data-back="Read Now"
                data-front="read more"
              />
            </div>
          </div>
          <div className="card card2">
            <div className="card_image">
              <a href>
                <img src="brol.png" className="logo-se" />
              </a>
            </div>
            <div className="card_title title-white">
              <h6 className="heading">PROPERTY CREATION</h6>
              <p>Intellectual Property Creation in Brosid Sports LLC</p>
            </div>
            <div className="btn-read">
              <Link
                to="/services#ipc"
                className="btn-flip"
                data-back="Read Now"
                data-front="read more"
              />
            </div>
          </div>
          <div className="card card3">
            <div className="card_image">
              <a href>
                <img src="brol.png" className="logo-se" />
              </a>
            </div>
            <div className="card_title title-white">
              <h6 className="heading">TV PRODUCTION</h6>
              <p>Insights into TV Production in Brosid Sports LLC</p>
            </div>
            <div className="btn-read">
              <Link
                to="/services#tp"
                className="btn-flip"
                data-back="Read Now"
                data-front="read more"
              />
            </div>
          </div>
          <div className="card card4">
            <div className="card_image">
              <a href>
                <img src="brol.png" className="logo-se" />
              </a>
            </div>
            <div className="card_title title-white">
              <h6 className="heading">SPONSORSHIP, MARKETING</h6>
              <p>
                Maximizing Sponsorship, Sales, and Marketing in Brosid Sports
                LLC
              </p>
            </div>
            <div className="btn-read">
              <Link
                to="/services#spm"
                className="btn-flip"
                data-back="Read Now"
                data-front="read more"
              />
            </div>
          </div>
          <div className="card card5">
            <div className="card_image">
              <a href>
                <img src="brol.png" className="logo-se" />
              </a>
            </div>
            <div className="card_title title-white">
              <h6 className="heading">CONTENT SALES</h6>
              <p>Strategies for Content Sales in Brosid Sports LLC</p>
            </div>
            <div className="btn-read">
              <Link
                to="/services#cs"
                className="btn-flip"
                data-back="Read Now"
                data-front="read more"
              />
            </div>
          </div>
          <div className="card card6">
            <div className="card_image">
              <a href>
                <img src="brol.png" className="logo-se" />
              </a>
            </div>
            <div className="card_title title-white">
              <h6 className="heading">SPORTS EVENT MANAGEMENT</h6>
              <p>Sports Event Management Strategies in Brosid Sports LLC</p>
            </div>
            <div className="btn-read">
              <Link
                to="/services#sem"
                className="btn-flip"
                data-back="Read Now"
                data-front="read more"
              />
            </div>
          </div>
          <div className="card card7">
            <div className="card_image">
              <a href>
                <img src="brol.png" className="logo-se" />
              </a>
            </div>
            <div className="card_title title-white">
              <h6 className="heading">TALENT REPRESENTATION</h6>
              <p>
                Talent Representation in Brosid Sports LLC: Maximizing Athlete
                Opportunities
              </p>
            </div>
            <div className="btn-read">
              <Link
                to="/services#tr"
                className="btn-flip"
                data-back="Read Now"
                data-front="read more"
              />
            </div>
          </div>
          <div className="card card8">
            <div className="card_image">
              <a href>
                <img src="brol.png" className="logo-se" />
              </a>
            </div>
            <div className="card_title title-white">
              <h6 className="heading">CONSULTANCY</h6>
              <p> Leveraging Expertise for Success</p>
            </div>
            <div className="btn-read">
              <Link
                to="/services#c"
                className="btn-flip"
                data-back="Read Now"
                data-front="read more"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Homepage;




