import React from "react";
import "../Footer/Footer.css";
import { BiSolidHome } from "react-icons/bi";
import { Link } from "react-router-dom";
import { FaHandPointRight } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaSquareXTwitter } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
const Footer = () => {
  return (
    <>
      <footer>
        <div className="top_header">
          <section className="sec">
            <span>
              <BiSolidHome />
            </span>
            <span>6525 N, Campbell Ave,Chicago, IL</span>
          </section>
          <section className="sec">
            <span>
              <FaHandPointRight />
            </span>
            <img src="brol.png" className="log-f" alt="logo" />
          </section>
          <section className="sec">
            <span>
              <MdEmail />
            </span>
            <span>Info@brosidsports.com</span>
          </section>
        </div>
        <span className="border-shape" />
        <div className="bottom_content">
          <section className="sec">
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/services">Services</Link>
             {/*
            <Link to="/client">Clients</Link>
             */}
            <Link to="/news">News</Link>
            <Link to="/contact">Contact</Link>
          </section>
        </div>
        <div className="sec-c">
          <a href="https://www.facebook.com/people/Brosid-Sports-Management/100063526736009/?mibextid=LQQJ4d">
            <FaFacebook className="logo-c" />
          </a>
          <a href="https://www.instagram.com/brosidsports/?igsh=dTFrcHQ2aDYxZTZu&utm_source=qr">
            <RiInstagramFill className="logo-c" />
          </a>
          {/*
      <a href="#"><FaSquareXTwitter className='logo-c' /></a>
      <a href="#"><IoLogoWhatsapp className='logo-c' /></a>
      <a href="#"><FaLinkedin className='logo-c' /></a>
      */}
        </div>
        <div className="copyright">
          Copyright © 2024 Brosid Sports LLC - All rights reserved | Designed by<a href="https://infogainsoft.com" className="li-b" target="_blank"> Infogainsoft.com</a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
