import React from "react";
import "../News/News.css";
const News = () => {
  return (
    <>
      <div className="text-head-div">
        <h1 className="text-head-div1">Brosid Sports News Updates</h1>
        <h4 className="text-head-div-3">
          Stay tuned to Brosid Sports for the latest updates and in-depth
          analysis on your favorite sports and athletes!
        </h4>
        <p className="text-head-div2">Biggest News and Highlights</p>
      </div>
      <div className="container-n">
        <div className="head-n"></div>
        <main>
          <div className="normal">
            <p className="demo-title">News</p>
            <div className="module">
              <div className="thumbnail">
                <img src="https://media.crictracker.com/media/featureimage/2021/05/Bat-and-Ball.jpg" />
                <div className="date">
                  <div>17</div>
                  <div>Dec</div>
                </div>
              </div>
              <div className="content">
                <div className="category">Photos</div>
                <h1 className="title">
                  Brosid T20 Bash 2022: Full Squads, Schedule, Where to Watch on
                  TV.
                </h1>
                <h2 className="sub-title">
                  Brosid T20 Bash 2022 marks its second season from December 17.
                </h2>
                <div className="description">
                  The Brosid T20 Bash 2022 is all set to kickstart with its
                  second season scheduled to commence on December 17 in
                  Bareilly, Uttar Pradesh. The competition will see 15 matches
                  being played with 12 teams competing for the coveted title
                  over a period .
                </div>
              </div>
            </div>
          </div>
          <div className="hover">
            <p className="demo-title">News</p>
            <div className="module">
              <div className="thumbnail">
                <img src="https://media.crictracker.com/media/attachments/1671944234307_Brosid.jpeg" />
                <div className="date">
                  <div>25</div>
                  <div>Dec</div>
                </div>
              </div>
              <div className="content">
                <div className="category">Photos</div>
                <h1 className="title">
                  Brosid T20 Bash 2022, Semi-Final 2 Review
                </h1>
                <h2 className="sub-title">
                  DPS Rudrapur will meet Brosid Cricket Club in the final.
                </h2>
                <p className="description">
                  DPS Rudrapur defeated Mustallions in the second semi-final of
                  the ongoing Brosid T20 Bash by six wickets to qualify for the
                  final of the event. Kshitij Rawat was adjudged the Player of
                  the Match and scored a brilliant half-century. The final match
                  will be played between Brosid Cricket Club and DPS Rudrapur on
                  Sunday.
                </p>
              </div>
            </div>
          </div>
          <div className="normal">
            <p className="demo-title">News</p>
            <div className="module">
              <div className="thumbnail">
                <img src="https://gutshotmagazine.com/fantasy-sports/wp-content/uploads/2022/12/Brosid-T20-Bash-2022.jpg" />
                <div className="date">
                  <div>28</div>
                  <div>Dec</div>
                </div>
              </div>
              <div className="content">
                <div className="category">Photos</div>
                <h1 className="title">
                  Brosid T20 Bash 2022 Squad Update and Live Streaming Details
                </h1>
                <h2 className="sub-title">
                  Brosid T20 Bash 2022 Squad Update and Live Streaming Details,
                  Venue Update
                </h2>
                <div className="description">
                  12 teams will participate in Brosid T20 Bash 2022. The teams
                  participating in the grand event are IK Collection,
                  Mustallions Cricket Club, Badaun Dynamites, Youth Alliance, 11
                  Bro Cricket Club, Khalifa Cricket Academy, Oasis Cricket
                  Academy, Himachal Strikers, Brosid Cricket Club, Palika
                  Cricket Academy, DPS Rudrapur, and the Embrossed Cricket Club.
                </div>
              </div>
            </div>
          </div>
          <div className="normal">
            <p className="demo-title">News</p>
            <div className="module">
              <div className="thumbnail">
                <img src="https://www.republicbharat.com/_next/image/?url=https%3A%2F%2Fimg.republicworld.com%2Ftr%3Aw-1280%2Cq-75%2Cf-auto%2Frbharatimage%2Flegendsintercontinentalt20league-171646308572716_9.webp&w=1920&q=75" />
                <div className="date">
                  <div>23</div>
                  <div>May</div>
                </div>
              </div>
              <div className="content">
                <div className="category">Photos</div>
                <h1 className="title">
                  ब्रेट ली के सामने पार्थिव तो स्वान के सामने दिलशान, IPL के बाद
                  क्रिकेट लीजेंड्स के बीच होगा संग्राम
                </h1>
                <h2 className="sub-title">
                  IPL के रोमांच के बीच नई T20 लीग लॉन्च हुई है। लीजेंड्स
                  इंटरकॉन्टिनेंटल T20 लीग लॉन्च हुई है।
                </h2>
                <div className="description">
                  IPL के रोमांच के बीच नई T20 लीग लॉन्च हुई है। लीजेंड्स
                  इंटरकॉन्टिनेंटल T20 लीग लॉन्च हुई है। बड़ी बात ये है कि ये
                  अमेरिका खेली जाएगी, जिसमें लीजेंड्स नजर आएंगे, Legends
                  Intercontinental T20 League, Parthiv Patel, America, T20
                  League, Bret Lee
                </div>
              </div>
            </div>
          </div>
          <div className="hover">
            <p className="demo-title">News</p>
            <div className="module">
              <div className="thumbnail">
                <img src="https://static.cricketaddictor.com/images/Legends%20Intercontinental%20T-20%20League.jpeg?q=80&width=1024&height=538" />
                <div className="date">
                  <div>23</div>
                  <div>May</div>
                </div>
              </div>
              <div className="content">
                <div className="category">Photos</div>
                <h1 className="title">
                  Legends Intercontinental T-20 League unveiled;
                </h1>
                <h2 className="sub-title">
                  Legends Intercontinental T-20 League unveiled in presence of
                  Lee, Dilshan, Swann, Patel
                </h2>
                <p className="description">
                  USA-based Brosid Sports LLC on Thursday announced the launch
                  of the inaugural edition of the Legends Intercontinental T-20
                  League during a press event at Shangri-La in New Delhi. The
                  league, featuring legendary stars from various continents, is
                  scheduled to take place from August 16 to 29, 2024 at Moosa
                  Stadium in Texas, USA.
                </p>
              </div>
            </div>
          </div>
          <div className="normal">
            <p className="demo-title">News</p>
            <div className="module">
              <div className="thumbnail">
                <img src="https://static.tnnbt.in/thumb/msid-110368917,thumbsize-61124,width-1280,height-720,resizemode-75/110368917.jpg" />
                <div className="date">
                  <div>23</div>
                  <div>May</div>
                </div>
              </div>
              <div className="content">
                <div className="category">Photos</div>
                <h1 className="title">Legends Intercontinental T20:</h1>
                <h2 className="sub-title">
                  शुरू होने जा रही है नई टी20 लीग, फिर से मैदान पर दिखेंगे
                  पार्थिव, ब्रेट ली जैसे कई दिग्ग्ज
                </h2>
                <div className="description">
                  दुनिया के सबसे बड़े क्रिकेट लीग यानी इंडियन प्रीमियर लीग की
                  तर्ज पर अब एक नई टी20 लीग की शुरुआत होने जा रही है। इस लीग का
                  आगाज 16 अगस्त से होगा और खिताबी मुकाबला 28 अगस्त को खेला
                  जाएगा। इस मुकाबले में भारतीय विकेटकीपर पार्थिव पटेल, ब्रेट ली,
                  तिलकरत्ने दिलशान जैसे दिग्गज बल्लेबाजी करते हुए नजर आएंगे।
                </div>
              </div>
            </div>
          </div>
          <div className="normal">
            <p className="demo-title">News</p>
            <div className="module">
              <div className="thumbnail">
                <img src="https://images.mykhel.com/webp/ph-big/2024/05/ap05_23_2024_000396b.jpg" />
                <div className="date">
                  <div>23</div>
                  <div>May</div>
                </div>
              </div>
              <div className="content">
                <div className="category">Photos</div>
                <h1 className="title">
                  Delhi Sports Images of The Day (23 May 2024)
                </h1>
                <h2 className="sub-title">
                  New Delhi: (L-R) Former cricketers Australiaâ€™s Brett Lee,
                  Indiaâ€™s Parthiv Patel
                </h2>
                <div className="description">
                  Former cricketers Australiaâ€™s Brett Lee, Englandâ€™s Graeme
                  Swann, Indiaâ€™s Parthiv Patel and Sri Lankaâ€™s Tillakaratne
                  Dilshan with others during the launch of the â€˜Legends
                  Intercontinental T20 Cricket Leagueâ€™, in New Delhi,
                  Thursday, May 23, 2024.
                </div>
              </div>
            </div>
          </div>
          <div className="hover">
            <p className="demo-title">News</p>
            <div className="module">
              <div className="thumbnail">
                <img src="https://www.republicbharat.com/_next/image/?url=https%3A%2F%2Fimg.republicworld.com%2Ftr%3Aw-1280%2Cq-75%2Cf-auto%2Frbharatimage%2Flegendsintercontinentalt20league-171646308572716_9.webp&w=1920&q=75" />
                <div className="date">
                  <div>23</div>
                  <div>May</div>
                </div>
              </div>
              <div className="content">
                <div className="category">Photos</div>
                <h1 className="title">
                  To Play In Legends Intercontinental T20 In Texas
                </h1>
                <h2 className="sub-title">
                  Brett Lee, Graeme Swann, Tillakaratne Dilshan and Parthiv are
                  among the notable players set to participate in the inaugural
                  Legends Intercontinental T20 league.
                </h2>
                <p className="description">
                  The tournament, organized by Brosid Sports LLC based in the
                  United States, will feature seven teams and is slated to take
                  place from August 15 to 29 in Texas.The announcement of the
                  league's launch was made on Thursday, with Lee, Swann,
                  Dilshan, and Parthiv in attendance.
                </p>
              </div>
            </div>
          </div>
          <div className="normal">
            <p className="demo-title">News</p>
            <div className="module">
              <div className="thumbnail">
                <img src="news.jpeg" />
                <div className="date">
                  <div>23</div>
                  <div>May</div>
                </div>
              </div>
              <div className="content">
                <div className="category">Photos</div>
                <h1 className="title">
                  Brosid Sports celebrates 15-years Legacy on partnering with
                  Legends Intercontinental League T20 Tournament
                </h1>
                <h2 className="sub-title">
                  Celebrating 15 Years of Excellence: Brosid Sports and the
                  Legends Intercontinental League T20 Partnership
                </h2>
                <div className="description">
                  Brosid Sports Private Limited, established in 2008, proudly
                  celebrates its 15-year legacy. Renowned for enhancing global
                  sports, Brosid Sports has significantly contributed to the
                  success of the Legents Intercontinental League T20 Tournament.
                  Their partnership has elevated the tournament's prestige and
                  expanded cricket's global reach. As they commemorate this
                  milestone, Brosid Sports continues to drive excellence in the
                  world of sports.
                </div>
              </div>
            </div>
          </div>
          <div className="normal">
            <p className="demo-title">News</p>
            <div className="module">
              <div className="thumbnail">
                <img src="https://i0.wp.com/timesoftaj.com/wp-content/uploads/2024/05/IMG-20240523-WA0042.jpg?w=1032&ssl=1" />
                <div className="date">
                  <div>23</div>
                  <div>May</div>
                </div>
              </div>
              <div className="content">
                <div className="category">Photos</div>
                <h1 className="title">
                  आधुनिक क्रिकेट में एक नए युग की शुरुआत
                </h1>
                <h2 className="sub-title">
                  लीजेंड्स इंटरकॉन्टिनेंटल टी20 लीग में खेलते नजर आयेंगे पूर्व
                  दिग्गज खिलाड़ी ब्रेट ली, पार्थिव पटेल और तिलकरत्ने दिलशान
                </h2>
                <div className="description">
                  नई दिल्ली. अमेरिका स्थित ब्रोसिड स्पोर्ट्स एलएलसी ने गुरुवार
                  को एक प्रेस इवेंट के दौरान लीजेंड्स इंटरकॉन्टिनेंटल टी-20 लीग
                  के उद्घाटन संस्करण के शुभारंभ की घोषणा की। कई महाद्वीपों के
                  दिग्गज सितारों से लैस यह लीग 16 से 29 अगस्त, 2024 तक अमेरिका
                  के टेक्सास के मूसा स्टेडियम में होने वाली है।
                </div>
              </div>
            </div>
          </div>
          <div className="hover">
            <p className="demo-title">News</p>
            <div className="module">
              <div className="thumbnail">
                <img src="news2.jpeg" />
                <div className="date">
                  <div>23</div>
                  <div>May</div>
                </div>
              </div>
              <div className="content">
                <div className="category">Photos</div>
                <h1 className="title">
                  Brosid Sports celebrates 15-year Legacy on partnering with
                  Legends Intercontinental League T20 Tournament
                </h1>
                <h2 className="sub-title">
                  Cricketing legends Brett Lee, TM Dilshan, Parthiv Patel and
                  Graeme Swann to have their attendance in the inaugural event
                </h2>
                <p className="description">
                  Mumbai, 28 May: With the advent of the Legends Intercontinen-
                  tal T20 Leagues first season, Brosid Sports is celebrating
                  fifteen years of its illustrious history in sports management
                  Established in 2008 Brosid Sports Private Limited (BSPL) has
                  become a promi- nent entity recognized for its skillful
                  development and marketing of multiple international sporting
                  properties.
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default News;
