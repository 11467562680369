import React, { useState } from "react";
import "../Contact/Contact.css";
import { IoHome } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const Contact = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateName = (name) => {
    const regex = /^[A-Za-z\s]+$/;
    if (!regex.test(name)) {
      return "Name can only contain alphabets and spaces.";
    }
    return '';
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) {
      return "Invalid email address.";
    }
    return '';
  };

  const validateMessage = (message) => {
    if (message.length < 10) {
      return "Message should be at least 10 characters long.";
    }
    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });

    let error = '';
    if (name === 'name') {
      error = validateName(value);
    } else if (name === 'email') {
      error = validateEmail(value);
    } else if (name === 'message') {
      error = validateMessage(value);
    }

    setErrors({
      ...errors,
      [name]: error
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const nameError = validateName(form.name);
    const emailError = validateEmail(form.email);
    const messageError = validateMessage(form.message);

    if (nameError || emailError || messageError) {
      setErrors({
        name: nameError,
        email: emailError,
        message: messageError
      });
      return;
    }

    setIsSubmitting(true);

    emailjs.send('service_e9w0psk', 'template_it4rgkh', form, 'gzOGlAoY36u4riaqy')
      .then((result) => {
        setIsSubmitting(false);
        Swal.fire("Success", "Your message has been sent!", "success");
      }, (error) => {
        setIsSubmitting(false);
        Swal.fire("Error", "Failed to send your message. Please try again later.", "error");
      });
  };

  return (
    <>
      <section className="section-c">
        <img src="cp.jpg" alt="contact" className="img-contact" />
        <div className="section-header-c">
          <div className="container-c">
            <h2 className="h2-b">Contact Us</h2>
            <p className="p-b">
              Welcome to BROSID Sports LLC, where passion meets performance! We're
              thrilled to connect with you. Whether you have questions about our
              products, need assistance with an order, or simply want to share
              your feedback, we're here to help.
            </p>
          </div>
        </div>
        <div className="container-c2">
          <div className="row-c">
            <div className="contact-info-c">
              <div className="contact-info-item-c">
                <div className="contact-info-icon-c">
                  <IoHome className="logo-u" />
                </div>
                <div className="contact-info-content-c">
                  <h4 className="h4-b">Address</h4>
                  <p className="p-b">
                    6525 N
                    <br /> Campbell Ave <br />
                    Chicago IL
                  </p>
                </div>
              </div>
              <div className="contact-info-item-c">
                <div className="contact-info-icon-c">
                  <MdEmail className="logo-u" />
                </div>
                <div className="contact-info-content-c">
                  <h4 className="h4-b">Email</h4>
                  <p className="p-b">Info@brosidsports.com</p>
                </div>
              </div>
            </div>
            <div className="contact-form-c">
              <form id="contact-form" onSubmit={handleSubmit}>
                <h2 className="h2-b">SEND MESSAGE</h2>
                <div className="input-box-c">
                  <input
                    type="text"
                    name="name"
                    value={form.name}
                    placeholder="Name"
                    onChange={handleChange}
                    required
                  />
                  {errors.name && <p className="error-message">{errors.name}</p>}
                </div>
                <div className="input-box-c">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={form.email}
                    onChange={handleChange}
                    required
                  />
                  {errors.email && <p className="error-message">{errors.email}</p>}
                </div>
                <div className="input-box-c">
                  <textarea
                    name="message"
                    value={form.message}
                    placeholder="Type Your Message..."
                    onChange={handleChange}
                    required
                  />
                  {errors.message && <p className="error-message">{errors.message}</p>}
                </div>
                <div className="input-box-c">
                  <input type="submit" value="Send" name="submit" disabled={isSubmitting} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <div className="responsive-map">
        <iframe
          src="https://www.google.com/maps/embed?origin=mfe&pb=!1m3!2m1!1s6525+N,+Campbell+Ave,Chicago,+IL!6i13"
          width={600}
          height={450}
          frameBorder={0}
          style={{ border: 0 }}
          allowFullScreen
        />
      </div>
    </>
  );
};

export default Contact;
