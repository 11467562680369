import React, { useState, useEffect } from 'react';
import '../Slider/Slider.css'; 
import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";

const Slider = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [fade, setFade] = useState(true); 

  const images = [
    's1.jpg',
    's2.jpg',
    's3.jpg',
    // Add more image paths as needed
  ];

  const titles = [
    'T20 BASH 2022 FULL SQUADS, SCHEDULE,LIVE STREAMING WITH',
    'THE STAR INDIAN WRESTLERS MADE THE TATA-',
    'WFI TIES UP WITH Brosid Sports LLC',
    
  ];

  const descriptions = [
    'Brosid Sports LLC',
    'WFI EVENT MORE BRIGHTER',
    'FOR COMMERCIAL PROFILING YOUR BUSINESS',
   
  ];

  const nextSlide = () => {
    setFade(true);
    setTimeout(() => {
      setSlideIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
      setFade(false);
    }, 500);
  };

  const prevSlide = () => {
    setFade(true);
    setTimeout(() => {
      setSlideIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
      setFade(false);
    }, 500);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval); 
  }, [slideIndex]);

  return (
    <div className="slider-container">
      <div className="slider-image" style={{ backgroundImage: `url(${images[slideIndex]})` }}></div>
      <div className="slider-content">
        <div className='cont-div'>
          <h1 className='text-sh'>{titles[slideIndex]}</h1>
          <h2 className='text-sh'>{descriptions[slideIndex]}</h2>
        </div>
        <div className="slider-buttons">
          <a className='butt' onClick={prevSlide}><CiCircleChevLeft /></a>
          <a className='butt' onClick={nextSlide}><CiCircleChevRight /></a>
        </div>
      </div>
    </div>
  );
};

export default Slider;
