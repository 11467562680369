import React from "react";
import "../About/About.css";
const About = () => {
  return (
    <>
      <h1 className="text-center ab-head">Welcome to Brosid Sports LLC</h1>
      <p className="text-center ab-sub">
        we pride ourselves on being a premier producer and distributor of sports
        content across a variety of international platforms. Our expertise in
        managing high-profile events and our careful handling of media rights
        have cemented our position as a leader in the industry.
      </p>
      <div className="container mt-5 mb-3">
        <div className="row-ab">
          <div className="col-md-6 p-4 p-sm-5 order-2 order-sm-1">
            <small
              className="text-uppercase"
              style={{ color: "rgb(239 13 51)" }}
            >
              About us
            </small>
            <h1 className="h2 mb-4" style={{ fontWeight: 600 }}>
              Company Overview and Achievements{" "}
            </h1>
            <p className="text-secondary" style={{ lineHeight: 2 }}>
              Brosid Sports has established itself as a leader in the sports
              media industry, known for producing and distributing premium
              sports content worldwide. We successfully launched the Brosid T20
              Bash, now in its second season, and recently introduced the
              Legends Intercontinental T20 League, featuring cricket legends
              like Brett Lee and Graeme Swann. Our strategic partnerships,
              including the Legends Intercontinental League, showcase our
              commitment to innovation and excellence. Celebrating 15 years of
              success, Brosid Sports continues to expand its global reach,
              enhance the viewer experience, and set new standards in sports
              entertainment. 
            </p>
          </div>
          <div className="col-md-6 p-0 text-center order-1 order-sm-2">
            <img
              src="https://images.pexels.com/photos/3184430/pexels-photo-3184430.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
              className="w-100"
              alt
            />
          </div>
          <div className="col-md-6 p-0 text-center">
            <img
              src="https://images.pexels.com/photos/2467506/pexels-photo-2467506.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
              className="w-100"
              alt
            />
          </div>
          <div className="col-md-6 p-4 p-sm-5">
            <small
              className="text-uppercase"
              style={{ color: "rgb(239 13 51)" }}
            >
              About us
            </small>
            <h1 className="h2 mb-4" style={{ fontWeight: 600 }}>
            Strategic Collaborations and Future Endeavors{" "}
            </h1>
            <p className="text-secondary" style={{ lineHeight: 2 }}>
              Brosid Sports LLC excels in forming strong industry relations by
              partnering with top-tier stakeholders worldwide. We lead the
              market in managing and distributing high-value media rights,
              including the Indian Premier League (IPL) and the Indian Badminton
              League (IBL). Our strategic alliances and commitment to excellence
              keep us at the forefront of the sports media landscape. Looking
              ahead, we aim to expand our global reach, innovate content
              delivery, and enhance viewer experiences through cutting-edge
              technology and new media ventures. Our future plans include
              tapping into emerging markets and exploring new opportunities in
              sports media and entertainment.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
