import React from "react";
import "../Navbar/Navbar.css";
import { Link } from "react-router-dom";
const Navbar = () => {
  const handleLinkClick = () => {
    const checkbox = document.getElementById("menu-toggle-checkbox");

    if (checkbox.checked) {
      checkbox.checked = false;
    }
  };
  return (
    <>
      <header>
        <input type="checkbox" name="menu-toggle" id="menu-toggle-checkbox" />
        <label className="menu-toggle" htmlFor="menu-toggle-checkbox">
          <span className="menu-toggle_line" />
          <span className="menu-toggle_line" />
          <span className="menu-toggle_line" />
        </label>
        <Link to="/">
          <img src="brol.png" href="#h" className="branding"></img>
        </Link>
        <nav>
          <ul>
            <li className="menu-item" data-menu-index={1}>
              <Link to="/" className="meu-item_link" onClick={handleLinkClick}>
                HOME
              </Link>
            </li>
            <li className="menu-item" data-menu-index={2}>
              <Link
                to="/about"
                className="meu-item_link"
                onClick={handleLinkClick}
              >
                ABOUT
              </Link>
            </li>
            <li className="menu-item" data-menu-index={3}>
              <Link
                to="/services"
                className="meu-item_link"
                onClick={handleLinkClick}
              >
                SERVICES
              </Link>
            </li>

            <li className="menu-item" data-menu-index={5}>
              <Link
                to="/news"
                className="meu-item_link"
                onClick={handleLinkClick}
              >
                NEWS
              </Link>
            </li>

            <li className="menu-item" data-menu-index={7}>
              <Link
                to="/contact"
                className="meu-item_link"
                onClick={handleLinkClick}
              >
                CONTACT
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
