import React,{useEffect} from "react";
import "../Services/Services.css";
import { useLocation } from 'react-router-dom';
const Services = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  return (
    <>
      <h1 className="serve-head">Services</h1>
      <p className="serve-sub">WE OFFER DIGITAL SOLUTIONS</p>
      <div className="alll">
      {/* <section className="services-section-r">
          <div className="services-container-r">
            <div className="first-serve-r">
              <h5 className="first-head-serve-r">MEDIA RIGHTS</h5>
              <p className="first-para-serve-r">
                Brosid Sports excels in licensing and monetizing sports media
                rights, making a significant impact in the industry. We own,
                manage, and promote a diverse array of sports media rights. Our
                impressive portfolio includes prestigious events like the Brosid
                T20 Bash and the Legends Intercontinental T20 League.
                Additionally, we handle rights for major properties such as the
                Bangladesh Cricket Board Rights, French Open Rights, EURO 2012
                Rights, Bangladesh & Sri Lanka Premier League Rights, and the
                digital rights for the Indian Premier League (IPL). Through our
                expertise and strategic partnerships, we continue to lead and
                innovate in the realm of sports media.
              </p>
            </div>
            <div className="second-serve-r">
              <img
                src="s1.jpg"
                alt="Service Image"
                className="second-serve-img-r"
              />
            </div>
          </div>
        </section>  */} 
        <section id="ipc" className="services-section-c">
          <div className="services-container-c">
            <div className="second-serve-c">
              <img
                src="s2.jpg"
                alt="Service Image"
                className="second-serve-img-c"
              />
            </div>
            <div className="first-serve-c">
              <h5 className="first-head-serve-c">
                INTELLECTUAL PROPERTY CREATION
              </h5>
              <p className="first-para-serve-c">
                Brosid Sports LLC are the global leaders in creating sports
                Intellectual Properties. Our in-house team excels in providing
                comprehensive solutions for Sports IPs, from initial concept to
                final execution. Brosid Sports LLC has successfully launched the
                largest international professional badminton league – the Indian
                Badminton League, which boasts the highest prize money in the
                history of the sport.Our expertise ensures that every aspect of
                a sports IP is meticulously planned and executed to perfection.
                Brosid Sports LLC is dedicated to elevating the standards of
                sports entertainment and innovation on a global scale.
              </p>
            </div>
          </div>
        </section>
        <section id="tp" className="services-section-r">
          <div className="services-container-r">
            <div className="first-serve-r">
              <h5 className="first-head-serve-r">TV PRODUCTION</h5>
              <p className="first-para-serve-r">
                Whether it is a live event or studio, in stadiums or on the
                court, in India or any other part of the world, we handle all
                production needs with ease. Brosid Sports LLC has the in-house
                expertise and experience to manage both LIVE and NON-LIVE
                PRODUCTIONS. Our team at Brosid Sports LLC has accumulated over
                10,000 hours of live production experience. We offer
                cost-effective solutions and manage the entire logistics for any
                LIVE PRODUCTION event.
              </p>
            </div>
            <div className="second-serve-r">
              <img
                src="s3.jpg"
                alt="Service Image"
                className="second-serve-img-r"
              />
            </div>
          </div>
        </section>
        <section id="spm" className="services-section-c">
          <div className="services-container-c">
            <div className="second-serve-c">
              <img
                src="s4.jpg"
                alt="Service Image"
                className="second-serve-img-c"
              />
            </div>
            <div className="first-serve-c">
              <h5 className="first-head-serve-c">
                SPONSORSHIP, SALES, MARKETING
              </h5>
              <p className="first-para-serve-c">
                Brosid Sports LLC provides unlimited access to premier sports
                and lifestyle events, as well as top personalities. Our
                connections with the biggest sporting properties in the country
                enable our clients to tap into prestigious sponsorship
                opportunities worldwide. Our expertise in negotiating, managing,
                and activating sponsorships helps companies create unique brand
                experiences.We pride ourselves on fostering meaningful
                relationships between brands and the sports community. Brosid
                Sports is committed to driving impactful sponsorships that
                resonate with audiences globally.
              </p>
            </div>
          </div>
        </section>
        <section id="cs" className="services-section-r">
          <div className="services-container-r">
            <div className="first-serve-r">
              <h5 className="first-head-serve-r">CONTENT SALES</h5>
              <p className="first-para-serve-r">
                We are one of the largest independent distributors of SPORTS and
                BOLLYWOOD content. Our content is sourced from our in-house
                reporters, a network of freelancers across the country, and
                international partnerships with various sporting agencies,
                events, and federations. We distribute content across all media
                platforms, including TV, audio, web, mobile, IPTV, and
                IN-FLIGHT. Our media production team offers seamless solutions
                for both rights holders and non-rights broadcasters. Each day,
                we generate more than 300 minutes of original content.
              </p>
            </div>
            <div className="second-serve-r">
              <img
                src="s5.jpg"
                alt="Service Image"
                className="second-serve-img-r"
              />
            </div>
          </div>
        </section>
        <section id="sem" className="services-section-c">
          <div className="services-container-c">
            <div className="second-serve-c">
              <img
                src="s6.jpg"
                alt="Service Image"
                className="second-serve-img-c"
              />
            </div>
            <div className="first-serve-c">
              <h5 className="first-head-serve-c">SPORTS EVENT MANAGEMENT</h5>
              <p className="first-para-serve-c">
                Our Sports Event Management services provide clients with
                unparalleled access and reach. We address various business
                needs, including sales promotions, road shows, product launches,
                product branding, exhibitions, conferences, corporate lunches
                and dinners, sporting hospitality, and many other services
                within the realm of Sports Event Management. By leveraging our
                extensive expertise and resources, we ensure each event is
                executed flawlessly and leaves a lasting impact. Brosid Sports
                LLC is dedicated to delivering exceptional experiences that
                align with our clients' strategic goals.
              </p>
            </div>
          </div>
        </section>
        <section id="tr" className="services-section-r">
          <div className="services-container-r">
            <div className="first-serve-r">
              <h5 className="first-head-serve-r">TALENT REPRESENTATION</h5>
              <p className="first-para-serve-r">
                Talent Management Services are a vital component of our
                comprehensive model and resource strategies. We specialize in
                representing celebrities from the worlds of sports, music, and
                entertainment. We foster close working relationships to enhance,
                develop, and maintain their artistic brand identity, while
                providing our clients with top-quality talent to elevate their
                commercial profile in the marketplace. Our dedicated team
                ensures that each talent receives personalized attention and
                strategic guidance. Brosid Sports LLC is committed to maximizing
                the potential and visibility of the talent we represent, driving
                success for both the talent and our clients.
              </p>
            </div>
            <div className="second-serve-r">
              <img
                src="s7.jpg"
                alt="Service Image"
                className="second-serve-img-r"
              />
            </div>
          </div>
        </section>
        <section id="c" className="services-section-c">
          <div className="services-container-c">
            <div className="second-serve-c">
              <img
                src="s8.jpg"
                alt="Service Image"
                className="second-serve-img-c"
              />
            </div>
            <div className="first-serve-c">
              <h5 className="first-head-serve-c">CONSULTANCY</h5>
              <p className="first-para-serve-c">
                At Brosid Sports LLC, we help marketers connect with consumers
                to build their branding power. We design strategic marketing and
                sales solutions for all kinds of sporting properties. With
                access to various events, federations, and associations, we work
                closely with them to provide tailor-made solutions for their
                respective products. By leveraging our extensive network and
                expertise, Brosid Sports LLC ensures impactful and effective
                marketing strategies. Our bespoke solutions are crafted to meet
                the unique needs of each client, enhancing their brand's
                visibility and engagement. Brosid Sports LLC is dedicated to
                driving results that resonate with audiences and deliver lasting
                value.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Services;
